export const state = () => ({
    vessels: [],
    achievements: null,
    webinars: [],
})

export const mutations = {
    SET_VESSELS(state, vessels) {
        state.vessels = vessels
    },

    SET_ACHIEVEMENTS(state, achievements) {
        state.achievements = achievements
    },

    SET_WEBINARS(state, webinars) {
        state.webinars = webinars
    },
}

export const getters = {
    newResults(state) {
        return state.vessels.filter((each) => !each.flags.includes('viewed'))
    },
    previousResults(state) {
        return state.vessels.filter((each) => each.flags.includes('viewed'))
    },
}

export const actions = {
    async fetchVessels(store) {
        const data = await this.$axios.$get('/api/vessel-results')
        store.commit('SET_VESSELS', data.data)
    },

    async fetchAchievements(store) {
        const data = await this.$axios.$get('/api/user/achievements')
        store.commit('SET_ACHIEVEMENTS', data)
    },

    async fetchWebinars(store) {
        const data = await this.$axios.$get('/api/webinars')
        store.commit('SET_WEBINARS', data)
    },
}
