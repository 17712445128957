export const state = () => ({
    message: '',
    type: 'success',
})

export const mutations = {
    SET_MESSAGE(state, message) {
        state.message = message
    },

    SET_TYPE(state, type) {
        state.type = type
    },
}

export const actions = {
    showNotification(store, message, type = 'success') {
        store.commit('SET_MESSAGE', message)
        store.commit('SET_TYPE', type)
    },

    hideNotification(store) {
        store.commit('SET_MESSAGE', null)
    },
}
