export const state = () => ({
    quiz: null,
    current: null,
    score: 0,
    validationResult: null,
    maxScore: 0,
})

export const getters = {
    currentQuestion(state) {
        return state.quiz.questions[state.current]
    },
}

export const mutations = {
    SET_QUIZ(state, quiz) {
        state.quiz = quiz
    },

    SET_CURRENT(state, value) {
        state.current = value
    },

    SET_SCORE(state, value) {
        state.score = value ?? 0
    },

    SET_VALIDATION_RESULT(state, result) {
        state.validationResult = result
    },

    SET_MAX_SCORE(state, maxScore) {
        state.maxScore = maxScore
    },
}

export const actions = {
    async fetchQuiz(store, quizId) {
        const data = await this.$axios.$get(`/api/quizzes/${quizId}`)
        store.commit('SET_QUIZ', data.quiz)
        store.commit('SET_CURRENT', data.current)
        store.commit('SET_SCORE', data.score)
        store.commit('SET_MAX_SCORE', data.maxScore)
    },

    async fetchScore(store, quizId) {
        const data = await this.$axios.$get(`/api/quizzes/${quizId}/score`)
        store.commit('SET_QUIZ', data.quiz)
        store.commit('SET_CURRENT', data.current)
        store.commit('SET_SCORE', data.score)
        store.commit('SET_MAX_SCORE', data.maxScore)
    },

    async validateAnswer(store, answer) {
        const result = await this.$axios.$post(
            `/api/quizzes/${store.state.quiz.uuid}/${store.getters['currentQuestion'].id}/validate`,
            { answer }
        )

        store.commit('SET_SCORE', result.score)
        store.commit('SET_VALIDATION_RESULT', {
            valid: result.valid,
            correct: result.correct,
        })
    },
}
