export const state = () => ({
    courses: [],
    products: [],
    current: 1,
})

export const mutations = {
    SET_COURSES(state, courses) {
        state.courses = courses
    },

    SET_PRODUCTS(state, products) {
        state.products = products
    },

    SET_CURRENT(state, value) {
        state.current = value
    },
}

export const actions = {
    async fetchCourses(store) {
        const response = await this.$axios.$get('/api/get-your-box/courses')
        store.commit('SET_COURSES', response)
        return response
    },

    async fetchProducts(store, courseId) {
        const response = await this.$axios.$get(`/api/get-your-box/products/${courseId}`)
        store.commit('SET_PRODUCTS', response.data)
        return response.data
    },
}
