import moment from 'moment'

export const state = () => ({
    selectedVessels: [],
    pickupLocations: [],
    selectedLocationId: null,
    dates: [],
    times: [
        { value: null, title: '' },
        { value: 8, title: '08:00-11:00' },
        { value: 11, title: '11:00-14:00' },
        { value: 14, title: '14:00-17:00' },
        { value: 17, title: '17:00-19:00' },
    ],
    selectedDate: null,
    selectedTime: null,
    step: 1,
})

export const mutations = {
    SET_SELECTED_VESSELS(state, vessels) {
        state.selectedVessels = vessels
    },

    SET_PICKUP_LOCATIONS(state, locations) {
        state.pickupLocations = locations
    },

    SET_SELECTED_LOCATION_ID(state, id) {
        state.selectedLocationId = id
    },

    SET_STEP(state, step) {
        state.step = step
    },

    SET_DATES(state, dates) {
        state.dates = dates
    },

    SET_SELECTED_DATE(state, date) {
        state.selectedDate = date
    },

    SET_SELECTED_TIME(state, time) {
        state.selectedTime = time
    },

    SET_PHONE_NUMBER_TO_ADDRESSES(state, phoneNumber) {
        state.pickupLocations = state.pickupLocations.map((each) => {
            return { ...each, phone: each.phone ? each.phone : phoneNumber }
        })
    },
}

export const getters = {
    selectedLocation(state) {
        return state.pickupLocations.find((each) => each.id === state.selectedLocationId) ?? null
    },

    selectedDate(state) {
        return state.dates.find((each) => each.value === state.selectedDate)?.title
    },

    selectedTime(state) {
        return state.times.find((each) => parseInt(each.value) === parseInt(state.selectedTime))?.title
    },
}

export const actions = {
    async fetchSelectedVessels(store, vessels) {
        store.commit('SET_STEP', 1)
        const query = new URLSearchParams()
        vessels.forEach((each) => query.append('vessels[]', each))
        const response = await this.$axios.$get(`/api/vessels?${query.toString()}`)
        store.commit('SET_SELECTED_VESSELS', response.data)
    },

    async fetchPickupLocations(store, vessels) {
        const query = new URLSearchParams()
        vessels.forEach((each) => query.append('vessels[]', each))
        const response = await this.$axios.$get(`/api/addresses?${query.toString()}`)
        store.commit('SET_PICKUP_LOCATIONS', response.data)
        store.commit('SET_SELECTED_LOCATION_ID', null)
    },

    async storeAddress(store, address) {
        store.commit('SET_PICKUP_LOCATIONS', [...store.state.pickupLocations, address])

        store.commit('SET_SELECTED_LOCATION_ID', address.id)
    },

    initializeDates(store) {
        const fromDate = moment().add(2, 'day')
        const endDate = moment().add(8, 'days')

        const days = [{ value: null, title: '' }]

        for (; fromDate.isBefore(endDate); fromDate.add(1, 'days')) {
            if (fromDate.day() !== 6 && fromDate.day() !== 0) {
                days.push({ value: fromDate.format('YYYY-MM-DD'), title: fromDate.format('MMM DD dddd, YYYY') })
            }
        }

        store.commit('SET_DATES', days)
        store.commit('SET_SELECTED_DATE', null)
        store.commit('SET_SELECTED_TIME', null)
    },
}
