import { ref, useContext } from '@nuxtjs/composition-api'


export default {
  __name: 'AppHeader',
  setup(__props) {

const { $auth } = useContext()
const navbar = ref()

function logout() {
    $auth.logout()
}

function closeMenu() {
    if (!navbar.value.classList.contains('show')) {
        return
    }
    new window.bootstrap.Collapse(navbar.value)
}

return { __sfc: true,$auth, navbar, logout, closeMenu }
}

}