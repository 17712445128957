const middleware = {}

middleware['alert'] = require('../middleware/alert.js')
middleware['alert'] = middleware['alert'].default || middleware['alert']

middleware['course_join'] = require('../middleware/course_join.js')
middleware['course_join'] = middleware['course_join'].default || middleware['course_join']

middleware['verification'] = require('../middleware/verification.js')
middleware['verification'] = middleware['verification'].default || middleware['verification']

middleware['vessel-results'] = require('../middleware/vessel-results.js')
middleware['vessel-results'] = middleware['vessel-results'].default || middleware['vessel-results']

export default middleware
