export const state = () => ({
    user: {},
    genders: [],
    professions: [],
    professionLevels: [],
    handednesses: [],
    centrumSizes: [],
})

export const mutations = {
    SET_USER(state, user) {
        state.user = user
    },

    ADD_ADDRESS(state, address) {
        state.user.addresses.push(address)
    },

    UPDATE_ADDRESS(state, address) {
        state.user.addresses = state.user.addresses.map((each) => (each.id === address.id ? address : each))
    },

    DELETE_ADDRESS(state, address) {
        state.user.addresses = state.user.addresses.filter((each) => each.id !== address.id)
    },

    SET_GENDERS(state, genders) {
        state.genders = genders
    },

    SET_PROFESSIONS(state, professions) {
        state.professions = professions
    },

    SET_PROFESSIONLEVELS(state, professionLevels) {
        state.professionLevels = professionLevels
    },

    SET_HANDEDNESSES(state, handednesses) {
        state.handednesses = handednesses
    },

    SET_CENTRUMSIZES(state, centrumSizes) {
        state.centrumSizes = centrumSizes
    },
}

export const actions = {
    async fetchForGeneralInformation(store) {
        const data = await this.$axios.$get('/api/user/general-and-profession-data')
        store.commit('SET_PROFESSIONS', data.professions)
        store.commit('SET_PROFESSIONLEVELS', data.professionLevels)
        store.commit('SET_HANDEDNESSES', data.handednesses)
        store.commit('SET_CENTRUMSIZES', data.centrumSizes)
        store.commit('SET_GENDERS', data.genders)
    },

    async fetchUser(store) {
        const data = await this.$axios.$get('/api/user')
        store.commit('SET_USER', data)
    },
}
