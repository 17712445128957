export const AlertMessage = () => import('../../components/AlertMessage.vue' /* webpackChunkName: "components/alert-message" */).then(c => wrapFunctional(c.default || c))
export const FramelessModal = () => import('../../components/FramelessModal.vue' /* webpackChunkName: "components/frameless-modal" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ContactFormError = () => import('../../components/ContactForm/Error.vue' /* webpackChunkName: "components/contact-form-error" */).then(c => wrapFunctional(c.default || c))
export const ContactFormSuccess = () => import('../../components/ContactForm/Success.vue' /* webpackChunkName: "components/contact-form-success" */).then(c => wrapFunctional(c.default || c))
export const DashboardModals = () => import('../../components/Dashboard/DashboardModals.vue' /* webpackChunkName: "components/dashboard-modals" */).then(c => wrapFunctional(c.default || c))
export const DashboardMobile = () => import('../../components/Dashboard/Mobile.vue' /* webpackChunkName: "components/dashboard-mobile" */).then(c => wrapFunctional(c.default || c))
export const DashboardMobilePrevious = () => import('../../components/Dashboard/MobilePrevious.vue' /* webpackChunkName: "components/dashboard-mobile-previous" */).then(c => wrapFunctional(c.default || c))
export const DashboardNewResults = () => import('../../components/Dashboard/NewResults.vue' /* webpackChunkName: "components/dashboard-new-results" */).then(c => wrapFunctional(c.default || c))
export const DashboardNoVessels = () => import('../../components/Dashboard/NoVessels.vue' /* webpackChunkName: "components/dashboard-no-vessels" */).then(c => wrapFunctional(c.default || c))
export const DashboardPreviousResults = () => import('../../components/Dashboard/PreviousResults.vue' /* webpackChunkName: "components/dashboard-previous-results" */).then(c => wrapFunctional(c.default || c))
export const DashboardProgressbar = () => import('../../components/Dashboard/Progressbar.vue' /* webpackChunkName: "components/dashboard-progressbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardSidebar = () => import('../../components/Dashboard/Sidebar.vue' /* webpackChunkName: "components/dashboard-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ELearningVideoModal = () => import('../../components/E-learning/VideoModal.vue' /* webpackChunkName: "components/e-learning-video-modal" */).then(c => wrapFunctional(c.default || c))
export const FormError = () => import('../../components/Form/Error.vue' /* webpackChunkName: "components/form-error" */).then(c => wrapFunctional(c.default || c))
export const FormGroup = () => import('../../components/Form/Group.vue' /* webpackChunkName: "components/form-group" */).then(c => wrapFunctional(c.default || c))
export const FormPasswordInput = () => import('../../components/Form/PasswordInput.vue' /* webpackChunkName: "components/form-password-input" */).then(c => wrapFunctional(c.default || c))
export const FormSelectInput = () => import('../../components/Form/SelectInput.vue' /* webpackChunkName: "components/form-select-input" */).then(c => wrapFunctional(c.default || c))
export const FormTextAreaInput = () => import('../../components/Form/TextAreaInput.vue' /* webpackChunkName: "components/form-text-area-input" */).then(c => wrapFunctional(c.default || c))
export const FormTextInput = () => import('../../components/Form/TextInput.vue' /* webpackChunkName: "components/form-text-input" */).then(c => wrapFunctional(c.default || c))
export const GetYourBoxProductCard = () => import('../../components/GetYourBox/ProductCard.vue' /* webpackChunkName: "components/get-your-box-product-card" */).then(c => wrapFunctional(c.default || c))
export const GetYourBoxSteps = () => import('../../components/GetYourBox/Steps.vue' /* webpackChunkName: "components/get-your-box-steps" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDown = () => import('../../components/Icon/ChevronDown.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconEye = () => import('../../components/Icon/Eye.vue' /* webpackChunkName: "components/icon-eye" */).then(c => wrapFunctional(c.default || c))
export const IconEyeSlash = () => import('../../components/Icon/EyeSlash.vue' /* webpackChunkName: "components/icon-eye-slash" */).then(c => wrapFunctional(c.default || c))
export const IconXCicrle = () => import('../../components/Icon/XCicrle.vue' /* webpackChunkName: "components/icon-x-cicrle" */).then(c => wrapFunctional(c.default || c))
export const IconXMark = () => import('../../components/Icon/XMark.vue' /* webpackChunkName: "components/icon-x-mark" */).then(c => wrapFunctional(c.default || c))
export const IllustrationDoctorLike = () => import('../../components/Illustration/DoctorLike.vue' /* webpackChunkName: "components/illustration-doctor-like" */).then(c => wrapFunctional(c.default || c))
export const LayoutAppFooter = () => import('../../components/Layout/AppFooter.vue' /* webpackChunkName: "components/layout-app-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutAppHeader = () => import('../../components/Layout/AppHeader.vue' /* webpackChunkName: "components/layout-app-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutAppHeaderAuthenticated = () => import('../../components/Layout/AppHeaderAuthenticated.vue' /* webpackChunkName: "components/layout-app-header-authenticated" */).then(c => wrapFunctional(c.default || c))
export const PickupRequestAddressForm = () => import('../../components/PickupRequest/AddressForm.vue' /* webpackChunkName: "components/pickup-request-address-form" */).then(c => wrapFunctional(c.default || c))
export const PickupRequestAddressSelector = () => import('../../components/PickupRequest/AddressSelector.vue' /* webpackChunkName: "components/pickup-request-address-selector" */).then(c => wrapFunctional(c.default || c))
export const PickupRequestDateSelector = () => import('../../components/PickupRequest/DateSelector.vue' /* webpackChunkName: "components/pickup-request-date-selector" */).then(c => wrapFunctional(c.default || c))
export const PickupRequestPhoneInputModal = () => import('../../components/PickupRequest/PhoneInputModal.vue' /* webpackChunkName: "components/pickup-request-phone-input-modal" */).then(c => wrapFunctional(c.default || c))
export const PickupRequestYourSelectedVessels = () => import('../../components/PickupRequest/YourSelectedVessels.vue' /* webpackChunkName: "components/pickup-request-your-selected-vessels" */).then(c => wrapFunctional(c.default || c))
export const ProfileAddressAndContactForm = () => import('../../components/Profile/AddressAndContactForm.vue' /* webpackChunkName: "components/profile-address-and-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ProfileDeleteRegistrationModal = () => import('../../components/Profile/DeleteRegistrationModal.vue' /* webpackChunkName: "components/profile-delete-registration-modal" */).then(c => wrapFunctional(c.default || c))
export const ProfileEditAddressModal = () => import('../../components/Profile/EditAddressModal.vue' /* webpackChunkName: "components/profile-edit-address-modal" */).then(c => wrapFunctional(c.default || c))
export const ProfileGeneralAndProfessionForm = () => import('../../components/Profile/GeneralAndProfessionForm.vue' /* webpackChunkName: "components/profile-general-and-profession-form" */).then(c => wrapFunctional(c.default || c))
export const QuizAnswerDropzone = () => import('../../components/Quiz/AnswerDropzone.vue' /* webpackChunkName: "components/quiz-answer-dropzone" */).then(c => wrapFunctional(c.default || c))
export const QuizAnswerSection = () => import('../../components/Quiz/AnswerSection.vue' /* webpackChunkName: "components/quiz-answer-section" */).then(c => wrapFunctional(c.default || c))
export const QuizDroppableAnswers = () => import('../../components/Quiz/DroppableAnswers.vue' /* webpackChunkName: "components/quiz-droppable-answers" */).then(c => wrapFunctional(c.default || c))
export const QuizMultipleAnswers = () => import('../../components/Quiz/MultipleAnswers.vue' /* webpackChunkName: "components/quiz-multiple-answers" */).then(c => wrapFunctional(c.default || c))
export const QuizPairingAnswers = () => import('../../components/Quiz/PairingAnswers.vue' /* webpackChunkName: "components/quiz-pairing-answers" */).then(c => wrapFunctional(c.default || c))
export const QuizSingleAnswer = () => import('../../components/Quiz/SingleAnswer.vue' /* webpackChunkName: "components/quiz-single-answer" */).then(c => wrapFunctional(c.default || c))
export const VesselMeshViewer = () => import('../../components/Vessel/MeshViewer.vue' /* webpackChunkName: "components/vessel-mesh-viewer" */).then(c => wrapFunctional(c.default || c))
export const VesselPickupRequests = () => import('../../components/Vessel/PickupRequests.vue' /* webpackChunkName: "components/vessel-pickup-requests" */).then(c => wrapFunctional(c.default || c))
export const VesselRegisteredVessels = () => import('../../components/Vessel/RegisteredVessels.vue' /* webpackChunkName: "components/vessel-registered-vessels" */).then(c => wrapFunctional(c.default || c))
export const VesselResultCommunityRespond = () => import('../../components/VesselResult/CommunityRespond.vue' /* webpackChunkName: "components/vessel-result-community-respond" */).then(c => wrapFunctional(c.default || c))
export const VesselResultPieChart = () => import('../../components/VesselResult/PieChart.vue' /* webpackChunkName: "components/vessel-result-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const VesselResultProgressStatus = () => import('../../components/VesselResult/ProgressStatus.vue' /* webpackChunkName: "components/vessel-result-progress-status" */).then(c => wrapFunctional(c.default || c))
export const VesselResultScoreBar = () => import('../../components/VesselResult/ScoreBar.vue' /* webpackChunkName: "components/vessel-result-score-bar" */).then(c => wrapFunctional(c.default || c))
export const VesselResultSteps = () => import('../../components/VesselResult/Steps.vue' /* webpackChunkName: "components/vessel-result-steps" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
