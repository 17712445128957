import result from '@/pages/quiz/_quiz_id/result.vue'

export const state = () => ({
    result: null,
    vessels_for_review: [],
    overall_score_stats: null,
    vessels_count: 0,
    reviews_count: 0,
    flaws: [],
})

export const mutations = {
    SET_RESULT(state, result) {
        state.result = result
    },

    SET_VESSELS_FOR_REVIEW(state, vessels) {
        state.vessels_for_review = vessels
    },

    SET_OVERALL_SCORE_STATS(state, stats) {
        state.overall_score_stats = stats
    },

    SET_FLAWS(state, flaws) {
        state.flaws = flaws
    },

    SET_VESSEL_FLAWS_FOR_REVIEW(state, payload) {
        state.vessels_for_review = state.vessels_for_review.map((each) => {
            if (each.id !== payload.vessel_id) {
                return each
            }

            return { ...each, form: { ...payload } }
        })
    },

    SET_VESSELS_COUNT(state, count) {
        state.vessels_count = count
    },

    SET_REVIEWS_COUNT(state, count) {
        state.reviews_count = count
    },

    INCREASE_REVIEWS_COUNT(state) {
        state.reviews_count = state.reviews_count + 1
    },
}

export const getters = {
    totalFlaws(state) {
        return state.result?.result.userReviews
            ?.map((each) => each.flaws)
            .map((flaws) => Object.values(flaws).filter((each) => !!each).length)
            .reduce((acc, value) => acc + value, 0)
    },

    hasEnoughReviews(state) {
        return state.reviews_count >= 3
    },
}

export const actions = {
    async fetchResult(store, id) {
        try {
            if (parseInt(store.state.result?.id) !== parseInt(id)) {
                store.commit('SET_RESULT', null)
            }
            const data = await this.$axios.$get(`/api/vessel-results/${id}`)
            store.commit('SET_RESULT', data)
        } catch (e) {
            return
        }
    },

    async fetchVesselsForReview(store, uuid) {
        if (store.state.flaws.length === 0) {
            await store.dispatch('fetchFlaws')
        }

        const flaws = {}
        store.state.flaws.forEach((each) => (flaws[each.value] = false))

        const data = await this.$axios.$get(`/api/vessel/for-feedback/${store.state.result.id}`)

        let vessels = data.vessels.map((each) => ({
            ...each,
            form: {
                vessel_id: each.id,
                use_my_name: false,
                comment: null,
                flaws,
            },
        }))
        store.commit('SET_VESSELS_FOR_REVIEW', vessels)
        store.commit('SET_VESSELS_COUNT', data.vessels_count)
        store.commit('SET_REVIEWS_COUNT', data.reviews_count)
    },

    async fetchFlaws(store) {
        const data = await this.$axios.$get('/api/vessel/flaws')
        store.commit('SET_FLAWS', data)
    },

    async fetchOverallScoreStats(store) {
        store.commit('SET_OVERALL_SCORE_STATS', [])
        const data = await this.$axios.$get(`/api/vessel/overall-score`)
        store.commit('SET_OVERALL_SCORE_STATS', data)
    },

    async saveVesselFeedback(store, payload) {
        await payload.post(`/api/vessel/for-feedback/${store.state.result.id}`)
        store.commit('SET_VESSEL_FLAWS_FOR_REVIEW', payload.data())
        store.commit('INCREASE_REVIEWS_COUNT')
    },
}
