export const state = () => ({
    genders: [],
})

export const mutations = {
    SET_GENDERS(state, genders) {
        state.genders = genders
    },
}

export const actions = {
    async fetchGenders(store) {
        const data = await this.$axios.$get('/api/genders')
        store.commit('SET_GENDERS', data)
    },
}
