export const state = () => ({
    webinars: [],
})

export const mutations = {
    SET_WEBINARS(state, webinars) {
        state.webinars = webinars
    },
}

export const actions = {
    async fetchWebinars(store) {
        const data = await this.$axios.$get('/api/webinars')
        store.commit('SET_WEBINARS', data)
    },
}
