export const state = () => ({
    sections: [],
})

export const mutations = {
    SET_SECTIONS(state, sections) {
        state.sections = sections
    },
}

export const actions = {
    async fetchSections(store) {
        const response = await this.$axios.$get('/api/e-learning')
        store.commit('SET_SECTIONS', response)
    },

    async setViewed(store, video) {
        const sections = store.state.sections.map((each) => {
            if (each.id !== video.section_id) {
                return each
            }

            const videos = each.videos.map((each) => ({
                ...each,
                viewed_by_user: each.id === video.id ? true : each.viewed_by_user,
            }))

            return { ...each, videos }
        })

        store.commit('SET_SECTIONS', sections)
    },
}
