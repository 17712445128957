import { useVesselResult } from '~/composables/useVesselResult'

export default async function ({ store, route, redirect, $axios }) {
    await store.dispatch('vessel-result/fetchResult', route.params.id)
    await store.dispatch('vessel-result/fetchVesselsForReview')

    const { currentStepByRoute, isLastStep, Step, nextStep } = useVesselResult(store.state['vessel-result'])

    if (!store.state['vessel-result'].result) {
        await redirect('/page-not-found')
    }

    const currentStep = currentStepByRoute(route.params.id, route.name)

    if (!currentStep) {
        return
    }

    if (1 > currentStep.condition(store.state['vessel-result'].result.result)) {
        await redirect(nextStep(route.params.id, currentStep.id))
    }

    if (isLastStep(route.params.id, currentStep.id)) {
        await $axios.put(`/api/vessel/${route.params.id}/flags`, { flag: 'viewed' })
    }
}
