import { computed, useContext, ref } from '@nuxtjs/composition-api'


export default {
  __name: 'AppHeaderAuthenticated',
  setup(__props) {

const { $auth } = useContext()
const navbar = ref()

const adminUrl = computed(function () {
    return process.env.LARAVEL_BACKEND_URL
})

function logout() {
    $auth.logout()
}

function closeMenu() {
    if (!navbar.value.classList.contains('show')) {
        return
    }
    new window.bootstrap.Collapse(navbar.value)
}

return { __sfc: true,$auth, navbar, adminUrl, logout, closeMenu }
}

}