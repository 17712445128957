
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    layout: 'authenticated',
    verified: true,
    head: {
        title: 'E-learning',
    },
    async fetch(ctx) {
        try {
            await ctx.store.dispatch('quiz/fetchScore', ctx.route.params.quiz_id)
        } catch (e) {
            await ctx.redirect('/page-not-found')
        }
    },

    methods: {
        async repeat() {
            await this.$router.push(`/quiz/${this.$store.state.quiz.quiz.uuid}/start`)
        },
    },
})
