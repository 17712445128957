export const state = () => ({
    registeredVessels: [],
    pickupRequests: [],
})

export const mutations = {
    SET_REGISTERED_VESSELS(state, vessels) {
        state.registeredVessels = vessels
    },

    SET_PICKUP_REQUESTS(state, pickupRequests) {
        state.pickupRequests = pickupRequests
    },
}

export const actions = {
    async fetchData(store) {
        const response = await this.$axios.$get('/api/vessels')
        store.commit('SET_REGISTERED_VESSELS', response.data)
    },

    async fetchPickupRequests(store) {
        const data = await this.$axios.$get('/api/pickuprequests')
        store.commit('SET_PICKUP_REQUESTS', data)
    },
}
